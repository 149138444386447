import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/index.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [

    //dashboard
    { path: '/', name: 'Home', component: Home },

    {
        path: '/index2',
        name: 'index2',
        component: () => import(/* webpackChunkName: "index2" */ '../views/index2.vue')
    },


    //auth
    {
        path: '/auth/login-boxed',
        name: 'login-boxed',
        component: () => import(/* webpackChunkName: "auth-login-boxed" */ '../views/auth/login_boxed.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/auth/lockscreen-boxed',
        name: 'lockscreen-boxed',
        component: () => import(/* webpackChunkName: "auth-lockscreen-boxed" */ '../views/auth/lockscreen_boxed.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/auth/pass-recovery-boxed',
        name: 'pass-recovery-boxed',
        component: () => import(/* webpackChunkName: "auth-pass-recovery-boxed" */ '../views/auth/pass_recovery_boxed.vue'),
        meta: { layout: 'auth' }
    },
    {
        path: '/auth/troca-senha-boxed',
        name: 'troca-senha-boxed',
        component: () => import(/* webpackChunkName: "auth-troca-senha-boxed" */ '../views/auth/troca_senha_boxed.vue'),
        meta: { layout: 'auth' }
    },


    //users
    {
        path: '/users/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "users-profile" */ '../views/users/profile.vue')
    },
    {
        path: '/users/account-setting',
        name: 'account-setting',
        component: () => import(/* webpackChunkName: "users-account-setting" */ '../views/users/account_setting.vue')
    },

    //apps
    {
        path: '/apps/pregoes',
        name: 'pregoes',
        component: () => import(/* webpackChunkName: "apps-pregoes" */ '../views/apps/pregoes/pregoes.vue')
    },
    {
        path: '/apps/busca',
        name: 'busca',
        component: () => import(/* webpackChunkName: "apps-busca" */ '../views/apps/busca/busca.vue')
    },
    {
        path: '/apps/alertas',
        name: 'alertas',
        component: () => import(/* webpackChunkName: "apps-alertas */ '../views/apps/alertas/alertas.vue')
    },

    //configs
    {
        path: '/configs/logins-portais',
        name: 'logins-portais',
        component: () => import(/* webpackChunkName: "configs-logins-portais" */ '../views/configs/loginsportal.vue')
    },

];

const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

router.beforeEach((to, from, next) => {
    let bUserLogado = store.getters.userLogado;
    let bTelaTrancada = store.getters.telaTrancada;

    if (!bUserLogado) { //verificando em localstorage
        let _userDados = JSON.parse(localStorage.getItem('userDados'));
        if (_userDados) {
            console.log("ROUTER _userDados = ", _userDados);
            console.log("ROUTER _userDados.token = ", _userDados.token);

            bTelaTrancada = JSON.parse(localStorage.getItem('telaTrancada'));
            if (_userDados && _userDados.token) {
                store.commit('changeUserLogado', true);
                store.commit('changeUserDados', _userDados);
                bUserLogado = true;
            }
        } else {
            console.log("Logina primeira vez...");
        }
    }

    if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
        store.commit('setLayout', 'auth');
    } else {
        if (!bUserLogado) {
            return next("/auth/login-boxed");
        }

        if (bTelaTrancada) {
            return next("/auth/lockscreen-boxed");
        }

        store.commit('setLayout', 'app');
    }
    next(true);
});

export default router;
