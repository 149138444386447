<template>
  <div class="layout-px-spacing dash_1">
    <portal to="breadcrumb">
      <ul class="navbar-nav flex-row">
        <li>
          <div class="page-header">
            <nav class="breadcrumb-one" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="javascript:;">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page"><span>Licitações</span></li>
              </ol>
            </nav>
          </div>
        </li>
      </ul>
    </portal>

    <div class="row layout-top-spacing">
      <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-sales-category">
          <div class="widget-heading">
            <h5>Licitações as últimas 24Hrs</h5>
          </div>
          <div class="widget-content">
            <apexchart height="460" type="donut" :options="chartOptions" :series="chartOptions.series"></apexchart>
          </div>
        </div>
      </div>

      <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <div class="widget widget-recent-orders">
          <div class="widget-heading">
            <h5>Últimas licitações adicionadas</h5>
          </div>
          <div class="widget-content">
            <b-table-simple responsive>
              <b-thead>
                <b-tr>
                  <b-th><div class="th-content">Portal</div></b-th>
                  <b-th><div class="th-content">Objeto</div></b-th>
                  <b-th><div class="th-content">Publicação</div></b-th>
                  <b-th><div class="th-content">Disputa</div></b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-if="!datalics">
                  <b-td colspan="4" class="loadregs">
                    <center>
                      <p><span>Carregando...</span></p>
                    </center>
                    <br />
                    <b-overlay :show="show_wait" rounded="sm"> </b-overlay>
                  </b-td>
                </b-tr>
                <b-tr v-else v-for="reg in datalics" :key="reg.id">
                  <b-td
                    ><div class="td-content text-primary">{{ reg.nomeportal }}</div></b-td
                  >
                  <b-td
                    ><div class="td-content">{{ reg.objeto }}</div></b-td
                  >
                  <b-td
                    ><div class="td-content">{{ formataData(reg.datapublicacao) }}</div></b-td
                  >
                  <b-td
                    ><div class="td-content">{{ formataDataHora(reg.datadisputa) }}</div></b-td
                  >
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
import fcgeral from '@/mixins/fcgeral';
import conserv from '@/api/conserv';
import '@/assets/sass/widgets/widgets.scss';

export default {
  mixins: [fcgeral, conserv],
  metaInfo: { title: 'Licitações disponíveis' },
  data() {
    return {
      show_wait: false,
      datalics: null,
      values_lics: [],
      labs_lics: [],
      colors_lics: [],
      is_dark: this.$store.state.is_dark_mode,
    };
  },
  async mounted() {
    await this.ListaLicitacoes24H();
    await this.ListaUltimasPortais();
  },
  computed: {
    chartOptions: function () {
      return {
        chart: {
          type: 'donut',
        },
        labels: this.labs_lics,
        series: this.values_lics,
        colors: this.colors_lics,
        noData: {
          text: 'Carregando...',
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      };
    },
  },
  methods: {
    async ListaLicitacoes24H() {
      let json = await this.connServ('api/DashLics/ListaLics24H', 'get', '');
      if (json.bstatus && json.dados.status) {
        let vals = json.dados.registro.map((a) => a.total);
        let labs = json.dados.registro.map((a) => a.nomeportal);
        let cores = json.dados.registro.map((a) => a.cor);
        this.colors_lics = cores;
        console.log('this.colors_lics = ', this.colors_lics);
        this.values_lics = vals;
        this.labs_lics = labs;
      } else {
        this.showAlert('error', json.msg);
      }
    },
    async ListaUltimasPortais() {
      this.show_wait = true;
      let json = await this.connServ('api/DashLics/ListaUltimasLics', 'get', '');
      if (json.bstatus && json.dados.status) {
        this.datalics = [...json.dados.registro];
      } else {
        this.showAlert('error', json.msg);
      }
      this.show_wait = false;
    },
  },
};
</script>

<style scoped>
.loadregs {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>