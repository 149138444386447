<template>
  <div>
    <!--  BEGIN NAVBAR  -->
    <Header></Header>
    <!--  END NAVBAR  -->

    <!--  BEGIN MAIN CONTAINER  -->
    <div
      class="main-container"
      id="container"
      :class="[!$store.state.is_show_sidebar ? 'sidebar-closed sbar-open' : '', $store.state.menu_style === 'collapsible-vertical' ? 'collapsible-vertical-mobile' : '']"
    >
      <!--  BEGIN OVERLAY  -->
      <div class="overlay" :class="{ show: !$store.state.is_show_sidebar }" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"></div>
      <div class="search-overlay" :class="{ show: $store.state.is_show_search }" @click="$store.commit('toggleSearch', !$store.state.is_show_search)"></div>
      <!-- END OVERLAY -->

      <!--  BEGIN SIDEBAR  -->
      <Sidebar></Sidebar>
      <!--  END SIDEBAR  -->

      <!--  BEGIN CONTENT AREA  -->
      <div id="content" class="main-content">
        <router-view>
          <!-- BEGIN LOADER -->
          <div id="load_screen">
            <div class="loader">
              <div class="loader-content">
                <div class="spinner-grow align-self-center"></div>
              </div>
            </div>
          </div>
          <!--  END LOADER -->
        </router-view>

        <!-- BEGIN FOOTER -->
        <Footer></Footer>
        <!-- END FOOTER -->
      </div>
      <!--  END CONTENT AREA  -->
    </div>
  </div>
</template>
<script>
import Header from '@/components/layout/header.vue';
import Sidebar from '@/components/layout/sidebar.vue';
import Footer from '@/components/layout/footer.vue';
export default {
  components: {
    Header,
    Sidebar,
    Footer,
  },
  data() {
    return {};
  },
};
</script>
