import axios from 'axios'

const api = axios.create({
    //baseURL: 'https://localhost:44322/'
    baseURL: 'https://apiuser.lancefacil.com/'
});

api.interceptors.request.use(config => {
    return config
}, error => Promise.reject(error))

api.interceptors.response.use(res => {
    return res
}, error => Promise.reject(error))

export default {
    methods: {
        async connServ(url, tpreq, dados) {
            //corrigindo headers
            let responseApi = { bstatus: false, dados: {}, msg: "" };
            var xTokenAuth = '';
            let user = JSON.parse(localStorage.getItem('userDados'));
            if (user) xTokenAuth = user.token;
            api.defaults.headers.common['Authorization'] = 'Bearer ' + xTokenAuth;
            api.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            api.defaults.headers.common['Access-Control-Expose-Headers'] = 'Content-Disposition';
            api.defaults.headers.common['Content-Type'] = 'application/json';

            if (tpreq == 'get') {
                await api.get(url).then((response) => {
                    responseApi.bstatus = true;
                    responseApi.dados = response.data;
                }).catch((error) => {
                    console.log('ERRO GET: ', error);
                    if (error == 'Error: Network Error') {
                        responseApi.bstatus = false;
                        responseApi.msg = 'Não foi possível conectar ao servidor!';
                        return;
                    }
                    responseApi.bstatus = false;
                    responseApi.msg = error.response.data.message;
                    if (error.response) {
                        if (error.response.status == 401) {
                            console.log("Enviando para login...");
                            this.$router.push({ name: "login-boxed" });
                        }
                    }
                });
            }

            if (tpreq == 'post') {
                console.log("Postando em ", dados, " ", url)
                await api.post(url, dados).then((response) => {
                    responseApi.bstatus = true;
                    responseApi.dados = response.data;
                }).catch((error) => {
                    console.log('ERRO POST: ', error);
                    if (error == 'Error: Network Error') {
                        responseApi.bstatus = false;
                        responseApi.msg = 'Não foi possível conectar ao servidor!';
                        return;
                    }
                    responseApi.bstatus = false;
                    responseApi.msg = error.response.data.message;
                    if (error.response) {
                        if (error.response.status == 401) {
                            this.$router.push({ name: "login-boxed" });
                        }
                    }
                });
            }

            if (tpreq == 'down') {
                console.log("download em ", dados, " ", url)
                await api.get(url, { responseType: 'blob' }).then((response) => {
                    if (responseApi.bstatus) {
                        responseApi.bstatus = true;
                        responseApi.dados = response.data;
                    }

                    const blob = new Blob([response.data], { type: response.data.type });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    const contentDisposition = response.headers['content-disposition'];
                    let fileName = 'unknown';
                    if (contentDisposition) {
                        fileName = contentDisposition.substring(contentDisposition.indexOf("=") + 1, contentDisposition.lastIndexOf(";"));
                    }
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);

                }).catch((error) => {
                    responseApi.bstatus = false;
                    responseApi.msg = error.response.data.message;
                    console.log('ERRO POST: ', error);
                    if (error.response) {
                        if (error.response.status == 401) {
                            this.$router.push({ name: "login-boxed" });
                        }
                    }
                });
            }

            return responseApi;
        }
    }
}

