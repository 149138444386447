<template>
  <div :class="[$store.state.layout_style, $store.state.menu_style]">
    <component v-bind:is="layout"></component>
    <go-top :size="50" bg-color="#90d4e1"></go-top>
  </div>
</template>
<script>
// layouts
import appLayout from './layouts/app-layout.vue';
import authLayout from './layouts/auth-layout.vue';
import '@/assets/sass/app.scss';

import ptMessages from 'devextreme/localization/messages/pt.json';
import { locale, loadMessages } from 'devextreme/localization';
import config from 'devextreme/core/config';
import GoTop from '@inotom/vue-go-top';

export default {
  metaInfo: {
    title: 'Lance Fácil Sistema',
    titleTemplate: '%s | Lance Fácil - Tecnologia em Licitação',
  },
  components: {
    app: appLayout,
    auth: authLayout,
    GoTop,
  },
  computed: {
    layout() {
      return this.$store.getters.layout;
    },
  },
  data() {
    return {};
  },
  async created() {
    loadMessages(ptMessages);
    locale(navigator.language);
    config({ defaultCurrency: 'BRL' });
  },
  mounted() {},
  methods: {},
};
</script>
