import moment from "moment";

export default {
  methods: {

    removeRegArray(coddel, regs) {
      var objDel = regs.find(i => i.id == coddel);
      var index = regs.indexOf(objDel);
      if (index > -1) {
        regs.splice(index, 1);
      }
      return regs;
    },

    formatReal(val) {

      var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });

      return formatter.format(val);
    },

    formataData(data) {
      if (!data)
        return '';

      return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    formataDataHora(data) {
      if (!data)
        return '';

      return moment(data, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY hh:mm");
    },

    verificaDataVal(dataval) {
      let dtReal = moment(dataval);
      let dataAgora = moment();

      console.log('verificando dataval = ', dtReal, dataAgora);
      if (dtReal < dataAgora) {
        console.log('DATA VALIDADE MENOR');
      } else {
        console.log('DATA VALIDADE MAIOR');
      }

      return dtReal > dataAgora;

    },

    async showAlert(icon, msg) {
      this.$swal({
        icon: icon,
        title: msg,
        padding: '2em',
        allowOutsideClick: false,
      });
    },

    async showToast(icon, msg) {
      const toast = this.$swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        padding: '2em'
      });
      toast.fire({
        icon: icon,
        title: msg,
        padding: '2em'
      });
    },

    showLoading(msg) {
      this.$swal({
        title: 'AGUARDE',
        html: msg,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
        willClose: () => {
        }
      }).then(function (result) {
        if (result.dismiss === this.$swal.DismissReason.timer) {
          console.log('I was closed by the timer');
        }
      });
    },

    closeLoading() {
      this.$swal().close();
    },

    async showConfirm(msg, funcback, params) {
      this.$bvModal
        .msgBoxConfirm(msg, {
          title: 'ATENÇÃO',
          okTitle: 'SIM',
          cancelTitle: 'NÃO',
          allowOutsideClick: false,
        })
        .then((value) => {
          console.log('value = ', value);
          if (value) funcback(params);
        })
        .catch((err) => {
          console.log(err);
        });
    },

  }
}

