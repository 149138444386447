<template>
  <!-- BEGIN FOOTER -->
  <div class="footer-wrapper">
    <div class="footer-section f-section-1">
      <p class="">
        Copyright © 2022 <a target="_blank" href="https://lancefacil.com">Lance Fácil - Soluções em licitações</a>, Todos os direitos reservados. Empresa:
        <strong
          ><span class="text-success">{{ userDados.razaosocial }} - {{ userDados.cnpj }}</span></strong
        >
      </p>
    </div>
    <div class="footer-section f-section-2">
      <p class="">
        Feito com
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-heart"
        >
          <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
        </svg>
      </p>
    </div>
  </div>
  <!-- END FOOTER -->
</template>
<script>
export default {
  data() {
    return {
      userDados: this.$store.getters.userDados,
    };
  },
  mounted() {},
  methods: {},
};
</script>
